.root {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--color-background);
  padding-top: 4rem;
  padding-bottom: 3rem;
  transition: background-color 225ms cubic-bezier(0.4, 0, 1, 1);
}

[data-theme='light'].root {
  --color-background: rgb(255, 255, 255);
  --color-kicker: var(--colors-grey600);
  --color-text: var(--colors-grey800);
  --color-description: var(--colors-grey600);
}

[data-theme='dark'].root {
  --color-background: rgb(17, 24, 39);
  --color-kicker: var(--colors-grey400);
  --color-text: var(--colors-white);
  --color-description: var(--colors-grey400);
}

.backdrop {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgb(17, 24, 39);
  filter: blur(1rem);
  opacity: 0;
  transition: opacity 225ms cubic-bezier(0.4, 0, 1, 1);
}

[data-theme='dark'] .backdrop {
  opacity: 0.99;
}

.partyLights {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: opacity 225ms cubic-bezier(0.4, 0, 1, 1);
}

[data-theme='dark'] .partyLights {
  background: linear-gradient(
    45deg,
    #fb0094,
    #0000ff,
    #00ff00,
    #ffff00,
    #ff0000,
    #fb0094,
    #0000ff,
    #00ff00,
    #ffff00,
    #ff0000
  );
  opacity: 0.25;
  background-size: 400%;
  animation: steam 20s linear infinite;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.textGlow {
  text-shadow: 0 0 15px #ff0;
  animation: glow 3s linear infinite;
}

@keyframes glow {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  max-width: 64rem;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.logo {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
}

[data-theme='dark'] .logo {
  filter: invert(100%);
}

.logo svg {
  height: 3rem;
  width: 100%;
}

.link {
  color: inherit;
  text-decoration: inherit;
}

.content {
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: left;
}

.kicker {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: var(--color-kicker);
}

.title {
  margin: 0;
  margin-top: 0.5rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  color: var(--color-text);
}

.description {
  margin: 0;
  margin-top: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--color-description);
  max-width: 32rem;
}

.actions {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  margin-top: 1.5rem;
}

[data-theme='dark'] .actions > a {
  filter: invert(100%);
}

.actionSeparator {
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--color-description);
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition-timing-function: opacity 225ms cubic-bezier(0.4, 0, 1, 1);
}

[data-theme='dark'] .background {
  opacity: 0;
}
