.root {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 6px;
  height: 32px;
  user-select: none;
  justify-content: space-between;
  max-width: 230px;
  cursor: pointer;
}

.assetContainer {
  display: flex;
}

.mainContainer {
  display: flex;
  align-items: center;
  overflow: hidden;
}
