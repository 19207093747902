@import './modern-normalize.css';
@import './variables.css';
@import './react-aria.css';

* {
  outline: none !important;
}

html {
  background-color: #fff;
}

html,
body,
#root {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  /* prevents a tiny flash of UI on the loader before the CSS reset is taken into account */
  margin: 0;
}

#root > div:first-child {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
