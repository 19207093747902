.container {
  display: flex;
  max-width: 100%;
}

.container:not(.containerInline) {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.left {
  padding: 2px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 1;
}

.left.material {
  border: 1px solid rgba(33, 36, 39, 0.05);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
}

.left.highlighted {
  background-color: var(--colors-grey200);
}

.media {
  margin-right: 8px;
}

.textLeft {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.containerCreate {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.labelCreate {
  flex-shrink: 0;
  margin-right: 6px;
  font-weight: 600;
}

.textCreate {
  white-space: nowrap;
  color: var(--colors-grey800);
  text-overflow: ellipsis;
  overflow: hidden;
}

.containerTextRight {
  margin-left: 8px;
  display: flex;
  flex-shrink: 2;
  justify-content: flex-end;
  overflow: hidden;
  min-width: 6ch;
}

.textRight {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  color: var(--colors-grey600);
}
