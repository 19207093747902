.root {
  width: 100%;
  padding: 8px;
}

.root.rootHovered {
  background-color: var(--colors-grey200);
}

.input {
  padding: 6px 8px;
  width: 100%;
  background-color: var(--colors-grey200);
  border-radius: 4px;
}

.input.inputHovered {
  background-color: #fff;
}
