.root {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 12px;
  height: 32px;
  user-select: none;
  justify-content: space-between;
  max-width: 230px;
}

.root.rootDisabled {
  cursor: not-allowed;
}

.root:not(.rootDisabled) {
  cursor: pointer;
}

.root.error {
  color: var(--colors-red600);
}

.root.rootHovered:not(.rootDisabled) {
  background-color: var(--colors-grey200);
}

.root.rootHovered.error:not(.rootDisabled) {
  background-color: var(--color-error-background);
}

.iconContainer {
  display: flex;
  margin-right: 8px;
}

.mainContainer {
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
}

.switchContainer {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
