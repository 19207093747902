.container {
  display: flex;
  flex-grow: 1;
  max-width: 100%;
}

.containerHovered {
  background-color: var(--colors-grey200);
}

.content {
  min-width: 200px;
  width: fit-content;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  pointer-events: auto;
}

.content:not(.contentNested) {
  padding: 8px 0;
}

.button {
  width: 100%;
}

.focusRing {
  box-shadow: 0 0 0 2px rgba(87, 56, 255, 0.15);
  border-radius: 8px;
}

.defaultCursor {
  cursor: default;
}

.overlay {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
}
