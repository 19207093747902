.day,
.daySelected {
  border-radius: 0 !important;
}

.day,
.daySelected {
  margin: 0;
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--colors-grey200);
  border-right: 1px solid var(--colors-grey200);
}

.react-datepicker__day.daySelected {
  font-weight: 600;
  color: #fff;
  background-color: var(--colors-folk-600);
}

.react-datepicker__day.daySelected:hover {
  background-color: #ffbb69;
}

.day:hover {
  color: var(--colors-folk-600);
  background-color: #5738ff22;
}

.fallback {
  width: 210px;
  height: 234px;
}
