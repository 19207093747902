:root {
  --color-error-background: #feedee;
  --color-light-grey-4: rgba(255, 255, 255, 0.75);
  --color-grey-background-hover: rgba(49, 53, 57, 0.07);
  --color-background-sidebar: var(--colors-grey100);
  --color-background-sidebar-active: var(--colors-grey200);
  --color-background-sidebar-focus: var(--colors-grey200);

  /* Padding */
  --padding-small: 4px;
  --padding-small2: 6px;
  --padding-medium: 8px;
  --padding-large: 12px;
  --padding-xlarge: 16px;
  --padding-xxlarge: 32px;

  /* Sidebar */
  --sidebar-width: 240px;

  /* Contact panel */
  --contact-panel-width: calc(
    100vw - var(--sidebar-width) - var(--padding-large)
  );
  --contact-details-width: calc(var(--contact-panel-width) * 0.3);
  --activities-width: calc(var(--contact-panel-width) * 0.7);

  /* Typography */
  --font-size-h1: calc(2.5rem + (5 * (100vw - 400px) / 624));
  --font-family-h1: 'IBM Plex Sans Bold, sans-serif';

  /* Elevation */
  --box-shadow: 0 0 8px 0 rgba(49, 53, 57, 0.15);
  --box-shadow-vertical: -1px 4px 4px 0 rgba(49, 53, 57, 0.2);
}
