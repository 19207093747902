.root {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}

@keyframes loader-animation {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(40px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.container {
  width: 20px;
  height: 14px;
  display: inline-block;
  overflow: hidden;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.2);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.content > .dot {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 22px;
  left: 10px;
}

.content > .dot:nth-child(1) {
  background: rgba(87, 56, 255, 0.15);
  animation: loader-animation 1s linear infinite;
  animation-delay: -0.5s;
}

.content > .dot:nth-child(2) {
  background: var(--colors-folk-600);
  animation: loader-animation 1s linear infinite;
  animation-delay: 0s;
}

.content > .dot:nth-child(3) {
  background: rgba(87, 56, 255, 0.15);
  animation: loader-animation 1s linear infinite;
  animation-delay: -0.5s;
}
