.option {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 32px;
  padding: 2px 8px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}

.option:hover,
.optionFocused {
  background-color: var(--colors-grey200);
}

.loader {
  padding: 0 10px;
}
